import { useId } from "react";
import clsx from "clsx";

export function Logomark({ invert = false, filled = false, ...props }) {
  let id = useId();

  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <rect
        clipPath={`url(#${id}-clip)`}
        className={clsx(
          "h-8 transition-all duration-300",
          invert ? "fill-white" : "fill-neutral-950",
          filled ? "w-8" : "w-0 group-hover/logo:w-8"
        )}
      />
      <use
        href={`#${id}-path`}
        className={invert ? "stroke-white" : "stroke-neutral-950"}
        fill="none"
        strokeWidth="1.5"
      />
      <defs>
        <path
          id={`${id}-path`}
          d="M3.25 26v.75H7c1.305 0 2.384-.21 3.346-.627.96-.415 1.763-1.02 2.536-1.752.695-.657 1.39-1.443 2.152-2.306l.233-.263c.864-.975 1.843-2.068 3.071-3.266 1.209-1.18 2.881-1.786 4.621-1.786h5.791V5.25H25c-1.305 0-2.384.21-3.346.627-.96.415-1.763 1.02-2.536 1.751-.695.658-1.39 1.444-2.152 2.307l-.233.263c-.864.975-1.843 2.068-3.071 3.266-1.209 1.18-2.881 1.786-4.621 1.786H3.25V26Z"
        />
        <clipPath id={`${id}-clip`}>
          <use href={`#${id}-path`} />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Logo({
  className,
  invert = false,
  filled = false,
  fillOnHover = false,
  ...props
}: {
  className?: string;
  invert?: boolean;
  filled?: boolean;
  fillOnHover?: boolean;
}) {
  return (
    <svg
      aria-hidden="true"
      className={clsx(
        "w-[130px] h-[32px]",
        fillOnHover && "group/logo",
        className
      )}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="3196.72131147541"
      height="1096.9876517853854"
      viewBox="0 0 3196.72131147541 1096.9876517853854"
    >
      <g transform="scale(9.836065573770492) translate(10, 10)">
        <defs id="SvgjsDefs1185" />
        <g
          id="SvgjsG1186"
          transform="matrix(6.033965917459257,0,0,6.033965917459257,1.1382626184474405,-30.117685552630938)"
          fill="#000"
        >
          <path d="M7.9 16.46 l2.08 1.88 c-1.12 1.24 -2.58 1.82 -4.14 1.82 c-2.88 0 -5.2 -2.16 -5.2 -5.08 s2.32 -5.1 5.2 -5.1 c1.56 0 3.02 0.58 4.14 1.82 l-2.08 1.88 c-0.44 -1.02 -1.4 -1.26 -2.06 -1.26 c-1.22 0 -2.24 1.08 -2.24 2.66 c0 1.56 1.02 2.64 2.24 2.64 c0.66 0 1.64 -0.18 2.06 -1.26 z M17.747708333333332 10.26 l-0.4 2.22 c-0.74 -0.32 -0.88 -0.26 -1.22 -0.26 c-1.2 0 -1.9 0.64 -1.9 2.22 l0 5.56 l-2.96 0 l0 -9.88 l2.96 0 l0 0.88 c0.38 -0.68 1.42 -1.02 2.08 -1.02 c0.62 0 0.98 0.02 1.44 0.28 z M22.815416666666664 12.42 c-1.22 0 -2.24 1.08 -2.24 2.66 c0 1.56 1.02 2.64 2.24 2.64 c1.24 0 2.26 -1.08 2.26 -2.64 c0 -1.58 -1.02 -2.66 -2.26 -2.66 z M22.815416666666664 9.98 c2.9 0 5.22 2.18 5.22 5.1 s-2.32 5.08 -5.22 5.08 c-2.88 0 -5.2 -2.16 -5.2 -5.08 s2.32 -5.1 5.2 -5.1 z M38.423125 14.36 l0 5.64 l-2.96 0 l0 -5.64 c0 -1.5 -0.4 -2.16 -1.56 -2.16 c-1.2 0 -1.9 0.66 -1.9 2.16 l0 5.64 l-2.96 0 l0 -9.88 l2.96 0 l0 0.94 c0.76 -0.74 1.8 -1.08 2.66 -1.08 c2.78 0 3.76 1.34 3.76 4.38 z M42.87083333333333 10.12 l0 9.88 l-2.96 0 l0 -9.88 l2.96 0 z M42.95083333333333 7.279999999999999 c0 0.84 -0.68 1.5 -1.58 1.5 c-0.86 0 -1.56 -0.66 -1.56 -1.5 c0 -0.82 0.7 -1.46 1.56 -1.46 c0.9 0 1.58 0.64 1.58 1.46 z M48.018541666666664 15.8 c0 0.72 -0.02 1.92 1.08 1.92 c0.54 0 0.88 -0.22 1.26 -0.5 l0 2.44 c-0.46 0.26 -1.02 0.5 -1.62 0.5 c-2.54 0 -3.68 -1.08 -3.68 -4.36 l0 -3.24 l-1.1 0 l0 -2.44 l1.1 0 l0 -2.84 l2.96 0 l0 2.84 l2.34 0 l0 2.44 l-2.34 0 l0 3.24 z" />
        </g>
      </g>
    </svg>
  );
}
